import clsx from 'clsx';

interface MicroformFieldProps {
  id: string;
  label: string;
}

export default function MicroformField({ id, label }: MicroformFieldProps): JSX.Element {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="relative flex h-[58px] flex-col gap-y-1 bg-transparent">
        <div
          className={clsx(
            'surface-white disabled:surface-grey-2 peer h-full w-full rounded-md border border-solid border-grey-7 px-3 pb-1 pt-4 text-16',
            '[&.flex-microform-focused]:outline-auto [&.flex-microform-focused]:outline-1 [&.flex-microform-focused]:outline-[#101010] [&.flex-microform-invalid]:border-red-1',
          )}
          id={id}
          data-testid="field-container"
        />

        <label
          className="absolute left-3 top-1/2 translate-y-[-20px] text-12 text-grey-7"
          htmlFor={id}
          data-testid="label"
        >
          {label}
        </label>
      </div>
    </div>
  );
}
