import { ChangeEvent, KeyboardEvent, useId, useState } from 'react';
import { useFormat } from '~/helpers/hooks/useFormat';

interface ExpirationFieldProps {
  onChange(month: string, year: string): void;
}

export default function ExpirationField({ onChange }: ExpirationFieldProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const id = useId();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    let inputValue = e.target.value;

    // Remove any non-digit characters
    inputValue = inputValue.replace(/\D/g, '');

    // Format input as MM / YYYY
    if (inputValue.length >= 2) {
      inputValue = inputValue.substring(0, 2) + ' / ' + inputValue.substring(2, 6);
    }

    // Limit to MM / YYYY format
    if (inputValue.length > 9) {
      inputValue = inputValue.slice(0, 9);
    }

    setValue(inputValue);
    const [month, year] = inputValue.split(' / ');
    onChange(month, year || '');
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const inputElement = e.currentTarget as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart;
    const inputValue = inputElement.value;

    if (e.key === 'Backspace' && cursorPosition === 5) {
      e.preventDefault();

      const newValue = inputValue.substring(0, 1);

      setValue(newValue);
      const [month, year] = newValue.split(' / ');
      onChange(month, year || '');
    }
  }

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="relative flex h-[58px] flex-col gap-y-1 bg-transparent">
        <input
          className="surface-white disabled:surface-grey-2 peer h-full w-full rounded-md border border-solid px-3 pb-1 pt-4 text-16"
          data-testid="expiration-field"
          id={id}
          type="text"
          pattern="^(0[1-9]|1[0-2]) \/ \d{4}$"
          value={value}
          required
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        <label
          className="absolute left-3 top-1/2 translate-y-[-20px] text-12 text-grey-7"
          htmlFor={id}
          data-testid="label"
        >
          {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.expiration_date_mm_yyyy' })}
        </label>
      </div>
    </div>
  );
}
