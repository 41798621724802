import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import { useFormat } from '~/helpers/hooks/useFormat';

interface PaypalProps {
  onChange(type: string): void;
}

export default function Paypal({ onChange }: PaypalProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="surface-grey-1 flex gap-2 rounded-md px-2 py-3">
      <FNCRadio
        className="flex-1 [&_label]:w-full [&_label]:font-semibold [&_label]:text-on-white"
        data-testid="paypal-method-field"
        label={formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.paypal' })}
        name="payment-method"
        value="paypal"
        required
        onChange={() => onChange('paypal')}
      />

      <PaymentMethod className="flex gap-2" visa={false} mastercard={false} maestro={false} deko={false} v12={false} />
    </div>
  );
}
