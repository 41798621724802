// Microform has no type
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Cart } from 'shared/types/cart';
import { mutate } from 'swr';
import { sdk } from '~/sdk';

export default function useMicroform() {
  const [microform, setMicroform] = useState<any>(null);
  const [cardNumber, setCardNumber] = useState<any>(null);
  const [securityCode, setSecurityCode] = useState<any>(null);

  function loadFields(captureContext: string) {
    // @ts-ignore: Flex Microform has no TypeScript
    const flex = new Flex(captureContext);
    const microformInstance = flex.microform({
      styles: {
        input: {
          'font-size': '16px',
          color: '#333333',
          'font-family': 'system-ui',
        },
      },
    });
    const cardNumber = microformInstance.createField('number');
    const securityCode = microformInstance.createField('securityCode');

    cardNumber.load('#microform-card-number-container');
    securityCode.load('#microform-security-code-container');

    setMicroform(microformInstance);
    setCardNumber(cardNumber);
    setSecurityCode(securityCode);
  }

  function unloadFields() {
    cardNumber?.unload();
    securityCode?.unload();
  }

  async function createToken(cardExpiration: { month: string; year: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      microform.createToken(
        {
          expirationMonth: cardExpiration.month,
          expirationYear: cardExpiration.year,
        },
        async (err: unknown, token: unknown) => {
          if (err) {
            reject(err);
          } else {
            const response = await sdk.callAction<Cart>({
              actionName: 'furniturechoice-cart/setMicroformTokenToCartPayment',
              payload: {
                isvToken: JSON.stringify(token),
              },
            });

            mutate('/action/cart/getCart', response);
            resolve();
          }
        },
      );
    });
  }

  return { loadFields, unloadFields, createToken };
}
