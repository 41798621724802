interface FormattedDataProps {
  label: string;
  value: string;
  icon: JSX.Element;
}

export default function Contact({ label, value, icon }: FormattedDataProps): JSX.Element {
  return (
    <div className="flex gap-x-2">
      <div className="[&>svg]:text-24">{icon}</div>

      <div>
        <p className="text-14 font-semibold leading-5">{label}</p>
        <p className="text-14 leading-5">{value}</p>
      </div>
    </div>
  );
}
