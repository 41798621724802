import { HTMLAttributes, useContext } from 'react';
import { useRouter } from 'next/navigation';
import clsx from 'clsx';
import { MdMailOutline, MdPhoneIphone } from 'react-icons/md';
import { Cart } from 'shared/types/cart';
import { useAccount } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import isSameBillingAndShippingAddress from '~/helpers/utils/furniturechoice/is-same-billing-and-shipping-address';
import FormattedAddress from './formatted-address';
import FormattedData from './formatted-data';
import Link from '../link';

interface CheckoutAddressSummaryProps extends HTMLAttributes<HTMLDivElement> {
  cart: Cart;
}

export default function CheckoutAddressSummary({
  className,
  cart,
  ...props
}: CheckoutAddressSummaryProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { billingAddress, shippingAddress } = cart;
  const router = useRouter();
  const { executeWithProgress, navigateWithProgress } = useContext(ProgressIndicatorContext);
  const { logout } = useAccount();

  async function handleLogout() {
    await executeWithProgress(() => logout());
    navigateWithProgress(() => router.push('/'));
  }

  return (
    <section
      className={clsx(
        'flex w-full flex-col gap-y-4',
        'after:mt-4 after:block after:h-px after:w-full after:bg-grey-3 after:content-[""]',
        className,
      )}
      {...props}
    >
      <header className="flex flex-col gap-y-2 lg:mt-4 lg:flex-row lg:items-center">
        <h2 className="font-sans text-20 font-semibold text-green-2">
          {formatMessage({ id: 'components.checkout-address-summary.billing_and_delivery_address' })}
        </h2>

        <div className="flex gap-x-3 text-12 text-grey-4 lg:ml-auto">
          <button onClick={handleLogout}>
            {formatMessage({ id: 'components.checkout-address-summary.not_you?' })}
          </button>

          <Link href="/checkout/information" prefetch={false}>
            {`< ${formatMessage({ id: 'components.checkout-address-summary.edit' })}`}
          </Link>
        </div>
      </header>

      <FormattedAddress type="billingAddress" cart={cart} />
      <FormattedData
        label={formatMessage({ id: 'components.checkout-address-summary.email' })}
        value={cart.email as string}
        icon={<MdMailOutline />}
      />
      <FormattedData
        label={formatMessage({ id: 'components.checkout-address-summary.contact' })}
        value={billingAddress?.phone as string}
        icon={<MdPhoneIphone />}
      />

      {!isSameBillingAndShippingAddress(cart.billingAddress, cart.shippingAddress) && (
        <div className="surface-grey-1 mt-2 flex flex-col gap-y-4 rounded-md px-4 py-5" data-testid="shipping-address">
          <p className="text-18 font-semibold">
            {formatMessage({ id: 'components.checkout-address-summary.deliver_to_a_different_address' })}
          </p>

          <FormattedAddress type="shippingAddress" cart={cart} />
          <FormattedData
            label={formatMessage({ id: 'components.checkout-address-summary.contact' })}
            value={shippingAddress?.phone as string}
            icon={<MdPhoneIphone />}
          />
        </div>
      )}
    </section>
  );
}
