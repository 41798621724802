import { useContext, useEffect, useState } from 'react';
import Script from 'next/script';
import { Cart } from 'shared/types/cart';
import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import Microform from './microform';

interface CybersourceProps {
  cart: Cart;
  isSelected: boolean;
  onChange(type: string): void;
}

export default function Cybersource({ cart, isSelected, onChange }: CybersourceProps): JSX.Element {
  const [isMicroformScriptReady, setIsMicroformScriptReady] = useState<boolean>(false);
  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  function handleReady() {
    setIsMicroformScriptReady(true);
    hideProgressIndicator();
  }

  useEffect(() => {
    showProgressIndicator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Script src="https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js" onReady={handleReady} />

      <div className="surface-grey-1 flex flex-col gap-y-4 rounded-md px-2 py-3">
        <div className="flex gap-2">
          <FNCRadio
            className="flex-1 [&_label]:w-full [&_label]:font-semibold [&_label]:text-on-white"
            data-testid="card-method-field"
            label={formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.credit_debit_card' })}
            name="payment-method"
            value="card"
            required
            onChange={() => onChange('card')}
          />

          <PaymentMethod className="flex gap-2" paypal={false} deko={false} v12={false} />
        </div>

        {isSelected && isMicroformScriptReady && <Microform cart={cart} />}
      </div>
    </>
  );
}
