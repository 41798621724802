import { PaypalResult } from 'shared/types/payment/paypal';
import { sdk } from '~/sdk';

export async function createPaypalTransaction() {
  const res = await sdk.callAction<PaypalResult>({
    actionName: 'furniturechoice-paypal/createTransaction',
    query: {},
  });

  return res;
}
