'use client';

import { Account } from 'shared/types/account';
import { Cart } from 'shared/types/cart';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import CheckoutAddressSummary from '~/components/furniturechoice/checkout-address-summary';
import CheckoutOrderSummary from '~/components/furniturechoice/checkout-order-summary';
import CheckoutShippingSummary from '~/components/furniturechoice/checkout-shipping-summary';
import { useCart } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';
import PaymentMethods from './payment-methods';

interface PaymentContentManagerProps {
  cartWithAccount: DataSourceV2<{ cart: Cart; account?: Account }>;
  financeTerms: DataSourceV2<FinanceProduct[]>;
}

export default function PaymentContentManager({
  data: { cartWithAccount, financeTerms },
}: TasticProps<PaymentContentManagerProps>) {
  const cartManager = useCart();

  return (
    <div className="container mb-20 mt-12 grid grid-cols-1 gap-x-12 gap-y-10 lg:mt-10 lg:grid-cols-2">
      <CheckoutOrderSummary cart={cartWithAccount.dataSource.cart} />

      <div className="flex w-full flex-col gap-y-8 lg:order-first lg:ml-auto lg:max-w-[481px]">
        <CheckoutAddressSummary cart={cartWithAccount.dataSource.cart} />
        <CheckoutShippingSummary cart={cartWithAccount.dataSource.cart} />
        <PaymentMethods
          cart={cartManager.data || cartWithAccount.dataSource.cart}
          financeProducts={financeTerms.dataSource}
        />
      </div>
    </div>
  );
}
