import { useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import { SDKResponse } from '@commercetools/frontend-sdk';
import { PaypalResult } from 'shared/types/payment/paypal';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { createPaypalTransaction } from './createPaypalTransaction';

export function PaypalContinueToPayBtn() {
  const [paypalToken, setPaypalToken] = useState('');
  const [paypalBusy, setPaypalBusy] = useState(false);
  const router = useRouter();

  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);

  const paypalSuccessHandler = (res: SDKResponse<PaypalResult>) => {
    if (res.isError) {
      setPaypalBusy(false);
      hideProgressIndicator();
      return;
    }

    const paypalResult: PaypalResult = res.data;

    //console.log(paypalResult);

    if (paypalResult.result == 'Failure') {
      // todo: alert user of error
      console.log(paypalResult.message);
    } else {
      setPaypalToken(paypalResult.token);
    }

    if (paypalResult.token) {
      const location = paypalResult.url + '?cmd=_express-checkout&useraction=commit&token=' + paypalResult.token;
      router.push(location);
      return;
    } else {
      setPaypalBusy(false);
      hideProgressIndicator();
    }
  };

  const handlePaypalClick = async () => {
    if (!paypalToken && !paypalBusy) {
      showProgressIndicator();
      setPaypalBusy(true);
      paypalSuccessHandler(await createPaypalTransaction());
    }
  };

  return (
    <FNCButton className="surface-green-2 w-full" onClick={handlePaypalClick} disabled={paypalBusy}>
      {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.continue_to_pay' })}
    </FNCButton>
  );
}
