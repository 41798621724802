import { useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { CreateApplicationPayload, CreateApplicationReply } from 'shared/types/payment/v12';
import { FinanceTermOption } from '~/components/furniturechoice/finance-calculator/finance-term-options';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sdk } from '~/sdk';

interface V12ButtonProps {
  financeProducts: FinanceProduct[];
  term: FinanceTermOption;
  deposit: string;
  amount: number;
}

export function V12ContinueToPayBtn({ financeProducts, term, deposit, amount }: V12ButtonProps) {
  const [v12Busy, setv12Busy] = useState(false);
  const router = useRouter();

  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);

  const handleV12Click = async () => {
    if (!v12Busy) {
      showProgressIndicator();
      setv12Busy(true);
    }

    const product = financeProducts.find((v) => v.productId == term.productId);

    if (product) {
      const query: CreateApplicationPayload = {
        price: amount.toString(),
        deposit: deposit,
        productguid: product.productGuid,
        productid: product.productId,
        tag: product.tag,
      };
      const res = await sdk.callAction<CreateApplicationReply>({
        actionName: 'furniturechoice-v12/createApplication',
        query: query as unknown as AcceptedQueryTypes,
      });

      if (!res.isError) {
        // failure
        if (res.data.result == 'Failure') {
          // todo: replace with better alert
          console.log(res.data.message);
          hideProgressIndicator();
          setv12Busy(false);
        }

        // success
        if (res.data.url) {
          router.push(res.data.url);
        } else {
          console.log('No redirect url');
          // todo: replace with better alert
          alert(res.data.message);
          hideProgressIndicator();
          setv12Busy(false);
        }
      } else {
        // todo: replace with better alert
        console.log(res.error);
        hideProgressIndicator();
        setv12Busy(false);
      }
    }
  };

  return (
    <FNCButton className="surface-green-2 w-full" onClick={handleV12Click} disabled={v12Busy}>
      {formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.continue_to_pay' })}
    </FNCButton>
  );
}
