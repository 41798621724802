import { Cart } from 'shared/types/cart';
import { mutate } from 'swr';
import { sdk } from '~/sdk';

export default async function setInitialPayment(): Promise<string> {
  const res = await sdk.callAction<Cart>({ actionName: 'furniturechoice-cart/addCybersourceCardPaymentToCart' });

  if (res.isError) {
    throw new Error();
  }

  mutate('/action/cart/getCart', res);

  return res.data.payments?.[0].custom?.isv_tokenCaptureContextSignature as string;
}
