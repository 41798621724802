import { Cart } from 'shared/types/cart';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import FinanceCalculatorComponent, { MIN_FINANCE_AMOUNT } from '~/components/furniturechoice/finance-calculator';
import { FinanceTermOption } from '~/components/furniturechoice/finance-calculator/finance-term-options';
import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import { useFormat } from '~/helpers/hooks/useFormat';

interface V12Props {
  onChange(type: string): void;
  isSelected: boolean;
  cart: Cart;
  financeProducts: FinanceProduct[];
  setFinanceTerm: (term: FinanceTermOption) => void;
  setFinanceDeposit: (deposit: string) => void;
}

export default function V12({
  onChange,
  isSelected,
  cart,
  financeProducts,
  setFinanceTerm,
  setFinanceDeposit,
}: V12Props): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  // only visible if cart amount exceeds min finance amount
  if (
    !(
      cart.sum &&
      cart.sum.centAmount &&
      cart.sum.fractionDigits &&
      cart.sum.centAmount / 10 ** cart.sum.fractionDigits >= MIN_FINANCE_AMOUNT
    )
  ) {
    return <></>;
  }

  return (
    <div className="surface-grey-1 rounded-md px-2 py-3">
      <div className="flex gap-2">
        <FNCRadio
          className="flex-1 [&_label]:w-full [&_label]:font-semibold [&_label]:text-on-white"
          data-testid="v12-method-field"
          label={formatMessage({ id: 'tastics.checkout.payment.payment-content-manager.finance' })}
          name="payment-method"
          value="v12"
          required
          onChange={() => onChange('v12')}
        />

        <PaymentMethod
          className="flex gap-2"
          visa={false}
          mastercard={false}
          maestro={false}
          paypal={false}
          deko={false}
          v12={true}
        />
      </div>
      {isSelected && (
        <div>
          <hr className="mt-4 border-grey-3" />
          <FinanceCalculatorComponent
            amount={cart.sum?.centAmount || 1000}
            financeProducts={financeProducts}
            currencyCode={cart.sum?.currencyCode || 'GBP'}
            configurableAmount={false}
            termCallback={setFinanceTerm}
            depositCallback={setFinanceDeposit}
          />
        </div>
      )}
    </div>
  );
}
