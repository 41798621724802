import { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';
import StepUp from './step-up';

interface DeviceDataCollectionProps {
  deviceDataCollectionUrl: string;
  jwt: string;
  onPaymentError(type: 'ddc' | 'step-up'): void;
}

type StepUpData = {
  stepUpUrl: string;
  responseJwt: string;
};

type CybersourceReturnPayload = { ok: boolean; payment: string; order: string };

export default function DeviceDataCollection({
  deviceDataCollectionUrl,
  jwt,
  onPaymentError,
}: DeviceDataCollectionProps): JSX.Element {
  const [stepUpData, setStepUpData] = useState<StepUpData | null>(null);
  const ref = useRef<HTMLFormElement>(null);
  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);
  const router = useRouter();

  async function handleDeviceDataCollectionResponse(event: MessageEvent) {
    if (event.origin === 'https://centinelapistag.cardinalcommerce.com') {
      const response = await sdk.callAction<CybersourceReturnPayload>({
        actionName: 'furniturechoice-cart/cybersourceCardPaymentCheckoutWithEnrollment',
      });

      if (response.isError) {
        try {
          const { code, recovery } = JSON.parse(response.error.message.split('Error: ')[1]);

          if (code === 'device_data_collection_error') {
            onPaymentError('ddc');
          } else if (code === 'authentication_error') {
            setStepUpData(recovery);
          }
        } catch (e) {
          onPaymentError('ddc');
        }

        hideProgressIndicator();
      } else {
        if (response.data.payment) {
          router.push('/orderacknowledge/' + response.data.payment);
        } else {
          hideProgressIndicator();
        }
      }
    }
  }

  async function handle3dsTransactionMessage(transactionId: string) {
    setStepUpData(null);

    showProgressIndicator();

    const response = await sdk.callAction<CybersourceReturnPayload>({
      actionName: 'furniturechoice-cart/cybersourceCardPaymentCheckoutWith3dsTransactionId',
      payload: { transactionId },
    });

    if (response.isError) {
      onPaymentError('step-up');
      hideProgressIndicator();
    } else {
      if (response.data.payment) {
        router.push('/orderacknowledge/' + response.data.payment);
      } else {
        hideProgressIndicator();
      }
    }
  }

  useEffect(() => {
    if (ref.current) {
      showProgressIndicator();
      ref.current.submit();
    }

    window.addEventListener('message', handleDeviceDataCollectionResponse, false);

    return () => {
      window.removeEventListener('message', handleDeviceDataCollectionResponse);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <iframe
        className="hidden"
        id="cardinal_collection_iframe"
        name="collectionIframe"
        height="10"
        width="10"
      ></iframe>

      <form
        ref={ref}
        className="hidden"
        id="cardinal_collection_form"
        method="POST"
        target="collectionIframe"
        action={deviceDataCollectionUrl}
      >
        <input id="cardinal_collection_form_input" type="hidden" name="JWT" value={jwt} />
      </form>

      {stepUpData && (
        <StepUp
          url={stepUpData.stepUpUrl}
          jwt={stepUpData.responseJwt}
          on3dsTransactionMessage={handle3dsTransactionMessage}
        />
      )}
    </>
  );
}
