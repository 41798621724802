import { useEffect, useRef } from 'react';

interface StepUpProps {
  url: string;
  jwt: string;
  on3dsTransactionMessage(transactionId: string): void;
}

export default function StepUp({ url, jwt, on3dsTransactionMessage }: StepUpProps): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }

    if (formRef.current) {
      formRef.current.submit();
    }

    function handle3dsTransactionMessage(event: MessageEvent) {
      if (event.data.transactionId3ds) {
        on3dsTransactionMessage(event.data.transactionId3ds);
      }
    }

    if (iframeRef.current) {
      window.addEventListener('message', handle3dsTransactionMessage);
    }

    return () => {
      window.removeEventListener('message', handle3dsTransactionMessage);
    };
  }, [on3dsTransactionMessage]);

  return (
    <dialog ref={dialogRef} className="rounded-md backdrop:bg-backdrop">
      <iframe ref={iframeRef} name="step-up-iframe" height="400" width="400"></iframe>
      <form ref={formRef} target="step-up-iframe" method="post" action={url}>
        <input type="hidden" name="JWT" value={jwt} />
      </form>
    </dialog>
  );
}
